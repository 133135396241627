import React from 'react'
import Helmet from 'react-helmet'

const PageHelmet = ({ seoObject }) => {
  if (!!seoObject) {
    return (
      <Helmet titleTemplate={`%s | H2 Group`}>
        <title>{`${seoObject.metaTitle}`}</title>
        <meta name="description" content={`${seoObject.metaDescription}`} />
      </Helmet>
    )
  }
  return null;
}

export default PageHelmet;